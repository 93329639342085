.modular-accordeon {
  #body-wrapper & .container {
    padding: 0;
  }
}

[data-accordeon-toggle] {
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0;

  .icon-plus {display: block}
  .icon-minus {display: none}

    &.open {
      color: $primary-color;
      .icon-plus {display: none}
      .icon-minus {display: block}
    }
}

[data-accordeon-content] {
    &.closed {
      display: none;
    }

    &.open {
      display: block;
    }
}