/** Extra columns spacing **/
.extra-spacing:not(.col-12), :not(.col12) > .e-content {
  padding-right: 1rem;

  @include breakpoint(md) {
    padding-right: 0;
  }
}

/** Breadcrumbs styling **/
#breadcrumbs {
  padding-left: 0;
  display: flex;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: 1rem;


  i {
    display: none;
  }

  span, a {
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
  }

  span, a {
    &:not(:first-child)::before {
      color: #e7e9ed;
      content: "/";
      padding-right: 1rem;
    }
  }
}

/** Blog Listing **/
.seminar-listing,
.blog-listing {

  .bricklayer-column {
    padding-left: 0px;
    padding-right: 25px;

  }
  .card {
    margin-bottom: 25px;
    border: 0;
    box-shadow: 0 10px 45px -9px rgba(0,0,0,0.1);
  }

  .card-footer {
    text-align: right;
  }

  .blog-date {
    font-size: 13px;
  }

  .label {

  }
}

/** Blog Item **/
.content-title {
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 0.5rem;
  }
}

.label {
  font-size: 12px;
  text-transform: uppercase;
}

.e-content {

  p {
    clear: both;
  }

  img {
    margin-top: 40px;
    margin-bottom: 40px;
    display: block;

    @media screen and (min-width: 800px) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width: 640px) {
    .bild-links {
      float: left;
      margin-right: 20px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .bild-rechts {
      float: right;
      margin-left: 20px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  &:after {
    display: table;
    clear: both;
    content: "";
  }
}


/** Pagination **/
ul.pagination {
  justify-content: center;
}

.prev-next {
  margin-top: 4rem;
}

/** Sidebar specific tweaks **/
#sidebar {

  ul.related-pages {
    box-shadow: none;
    padding: 0;
    z-index: 1;

    li {
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom: 0;
      }
    }

  }

  ul.archives {
    list-style: none;
    margin-left: 0;

    .label {
      vertical-align: text-top;
    }
  }
}

.blog-listing-excerpt {

  h1, h2, h3, h4, h5, h6 {
    font-size: 0.8rem;
    line-height: 1.3;
    color: $body-font-color;
  }
}

.sidebar-latest-posts {
  list-style-type: none;
  margin: 0;

  small {
    color: #454545;
  }
}

