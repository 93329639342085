.calendar-toggle  {
    border: 2px solid #8e8e8e;
    border-radius: 4px;
    overflow: hidden;
    background: white;
    padding: 0;
    cursor: pointer;
    margin-bottom: 20px;

    .calendar-view,
    .list-view {
        display: inline-block;
        padding: 3px 10px;
        font-weight: bold;
        color: #4e4e4e;
    }

    &.calendar-view-active .calendar-view,
    &.list-view-active .list-view {
        background: $primary-color;
        color: white;
    }
}