.tooltip {
  position: relative;

  &:before {
    font-size: 1rem;
    font-family: "FontAwesome";
    content: "\f059";
    position: absolute;
    left: 100%;
    top: -3px;
    color: $primary-color;
  }

  &:after {
    font-size: 11px;
    line-height: 13px;
    left: 100%;
    right: 0;
    transform: none;
    white-space: initial;
    width: 240px;
  }

  + .required {
    margin-left: 1rem;
  }
}