.sendinblue-message {

    &.error {
        color: red;
    }

    &.success {
        color: green;
    }

}

.sendinblue-specialinput {display: none}