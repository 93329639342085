// Sticky Footer solution
body.sticky-footer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #page-wrapper {
    flex: 1 0 auto;
  }
}

.page-footer a {
  color: white;
}

.footer-social-media-icon {
  display: inline-block;
  height: 1rem;
  width: auto;
}

.footer-social-media-link {
  margin-left: 0.7rem;
  display: inline-block;
}

#footer {
  color: #acb3c2;
  padding: 0 $horiz-padding 0;
  text-align: center;
  font-size: 0.6rem;
}