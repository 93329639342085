.seminarsuche {
  margin-top: 2rem;
  margin-bottom: 3rem;

  label {
    font-weight: bold;
    color: $primary-color;
  }

  input {
    border-color: $primary-color;
    border-radius: 4px;
  }
}

.seminar-card .blog-date {
  font-size: inherit;
  float: right;
  color: $body-font-color;
}

.seminar-card .card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.card-title .u-url {
  color: inherit;
  text-decoration: none;
}


.seminar-page.fbb .seminar-headline,
.seminar-page.fbb .card-title h4,
.seminar-card.fbb .card-title h5 {
    color: $fbb-color;
}


.seminar-page.hvn .seminar-headline,
.seminar-page.hvn .card-title h4,
.seminar-card.hvn .card-title h5 {
    color: $hvn-color;
}

.seminar-page.qm .seminar-headline,
.seminar-page.qm .card-title h4,
.seminar-card.qm .card-title h5 {
  color: $qm-color;
}


.seminar-page.nlf .seminar-headline,
.seminar-page.nlf .card-title h4,
.seminar-card.nlf .card-title h5 {
    color: $nlf-color;
}